export default {
  table1: {
    visible: true,
    isLoading: false,
    dataSet: [],
    onRowClick: function() {},
    totalRecords: 0,
    options: {
      filterByColumn: true,
      title: "Increasing Sales",
      subtitle: "Accounts with increasing total sales [click for details]",
      perPage: 10,
      disablePerPageDropdown: false,
      perPageValues: [],
      //selectableRows: true
      formatColumns: [
        {
          name: "Absolute Change",
          style: { style: "currency", currency: "USD" },
          type: "number"
        },
        {
          name: "Last 2 month sales",
          style: { style: "currency", currency: "USD" },
          type: "number"
        },
        {
          name: "Two months prior sales",
          style: { style: "currency", currency: "USD" },
          type: "number"
        },
        {
          name: "Percent change",
          style: { style: "percent" },
          type: "number"
        }
      ]
    }
  },
  table2: {
    visible: true,
    isLoading: false,
    dataSet: [],
    onRowClick: function() {},
    totalRecords: 0,
    options: {
      filterByColumn: true,
      title: "New Accounts",
      subtitle: "Biggest new accounts [click for details]",
      perPage: 10,
      disablePerPageDropdown: false,
      perPageValues: [],
      //selectableRows: true
      formatColumns: [
        {
          name: "Total Invoice Sales",
          style: { style: "currency", currency: "USD" },
          type: "number"
        }
      ]
    }
  },
  table3: {
    visible: true,
    isLoading: false,
    dataSet: [],
    onRowClick: function() {},
    totalRecords: 0,
    options: {
      filterByColumn: true,
      title: "Overdue Accounts",
      subtitle: "Overdue regular accounts [click for details]",
      perPage: 10,
      disablePerPageDropdown: false,
      perPageValues: [],
      //selectableRows: true
      formatColumns: [
        {
          name: "Average Invoice Sale",
          style: { style: "currency", currency: "USD" },
          type: "number"
        }
      ]
    }
  },
  table4: {
    visible: true,
    isLoading: false,
    dataSet: [],
    onRowClick: function() {},
    totalRecords: 0,
    options: {
      filterByColumn: true,
      title: "Decreasing Sales",
      subtitle: "Overdue regular accounts [click for details]",
      perPage: 10,
      disablePerPageDropdown: false,
      perPageValues: [],
      //selectableRows: true
      formatColumns: [
        {
          name: "Last 2 month sales",
          style: {
            style: "currency",
            currency: "USD"
          }
        },
        {
          name: "Two months prior sales",
          style: {
            style: "currency",
            currency: "USD"
          }
        },
        {
          name: "Percent change",
          style: {
            style: "percent"
          }
        },
        {
          name: "Absolute Change",
          style: {
            style: "decimal"
          }
        }
      ]
    }
  }
};
