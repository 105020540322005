export default {
  filteringPanel: {
    loaded: false,
    selected: {},
    districts: [],
    filters: [
      {
        type: "select",
        dataType: "territory",
        title: "Territory",
        tooltip: "Distributor billing territory",
        name: "territory",
        trackby: "id",
        label: "label",
        multiple: true,
        options: [],
        selected: {},
        visible: true
      },
      {
        type: "select",
        dataType: "string",
        title: "Country",
        tooltip: "Account billing country",
        name: "country",
        trackby: "id",
        label: "label",
        multiple: true,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "State",
        tooltip: "Account billing state",
        name: "state",
        trackby: "id",
        label: "label",
        multiple: true,
        options: [],
        selected: {}
      },
      {
        type: "select",
        dataType: "string",
        title: "City",
        tooltip: "Account billing city",
        name: "city",
        trackby: "id",
        label: "label",
        multiple: true,
        options: [],
        selected: {}
      },
      {
        type: "daterange",
        dataType: "datetime",
        defaultRange: "Last quarter",
        title: "Period",
        tooltip: "Sales period",
        name: "period",
        options: []
      }
    ]
  }
};
