<template>
  <div class="animated fadeIn">
    <b-card>
      <h2>Sales Overview - Distribution</h2>
      <br />
      <b-row>
        <b-col>
          <filtering-panel
            ref="filteringPanel"
            mode="server"
            dataset-name="distribution-sales-overview"
            :show-labels="false"
            :compact="false"
            :loaded="filteringPanel.loaded"
            :load-dictionaries="loadDictionaries"
            :districts="filteringPanel.districts"
            :filters="filteringPanel.filters"
            @dict-loaded="onFilteringPanelLoad"
            @search="getData"
            @change="filterData"
            @custom-filter-selected="onCustomFilterSelected"
            @state-changed="onFilteringPanelStateChange"
          />
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <table-custom
            ref="table1"
            :name="`custom_table_sales_overview_1`"
            :data="tables.table1.dataSet"
            :options="tables.table1.options"
            @row-click="tables.table1.onRowClick"
          />
        </b-col>

        <b-col>
          <table-custom
            ref="table2"
            :name="`custom_table_sales_overview_2`"
            :data="tables.table2.dataSet"
            :options="tables.table2.options"
            @row-click="tables.table2.onRowClick"
          />
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col>
          <table-custom
            ref="table3"
            :name="`custom_table_sales_overview_3`"
            :data="tables.table3.dataSet"
            :options="tables.table3.options"
            @row-click="tables.table3.onRowClick"
          />
        </b-col>
        <b-col>
          <table-custom
            ref="table4"
            :name="`custom_table_sales_overview_4`"
            :data="tables.table4.dataSet"
            :options="tables.table4.options"
            @row-click="tables.table4.onRowClick"
          />
        </b-col>
      </b-row>

      <hr />
    </b-card>
  </div>
</template>

<script>
import TableCustom from "@/components/TableCustom";
import FilteringPanel from "@/components/FilteringPanel";
import fp from "./filters";
import tables from "./tables";
import { mapActions } from "vuex";

export default {
  name: "Overview",
  components: {
    FilteringPanel,
    TableCustom
  },
  data: function() {
    return {
      isLoading: false,
      filteringPanel: fp.filteringPanel,
      tables: tables
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions("filteringPanel", [
      "loadSettings",
      "saveState",
      "saveSelectedFilters"
    ]),
    async loadDictionaries() {
      const territories = async () => {
        let response = await this.$api.get("dictionaries/territories");

        this.filteringPanel.filters.find(
          f => f.name === "territory"
        ).options = response.map(u => ({
          id: u.ID,
          label: u.Name
        }));
      };

      const districts = async () => {
        let response = await this.$api.post("dictionaries/countries/mapped", {
          ignore_restrictions: false
        });

        this.filteringPanel.districts = response;

        this.filteringPanel.filters.find(
          f => f.name === "country"
        ).options = this.$helpers.getDistinctArray(
          response,
          "country",
          "country",
          "id",
          "label"
        );
      };
      Promise.all([territories(), districts()]).then(
        () => (this.filteringPanel.loaded = true)
      );
    },

    onCustomFilterSelected() {
      this.getData();
    },

    onFilteringPanelLoad() {
      this.getData();
    },
    onFilteringPanelStateChange() {
      //this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder.visible;
    },
    filterData(e) {
      this.filteringPanel.selected = e;
      //let self = this;
      //this.filteringPanel.selected = e;
    },
    getData() {
      let fp = this.$refs.filteringPanel;

      let f = this.$refs.filteringPanel.selected;

      const onRowClick = function(event) {
        /*
        this.$store.dispatch(
          "filterPanel.selected.accountname",
          event.row["Account Name"]
        );
        */
        //this.prepareDrillDownFilters(event.row["Account Name"]);

        this.$form.prepareDrillDownFilters(
          this.$refs.filteringPanel,
          event.row["Account Name"]
        );

        this.$router.push({
          name: "Account overview"
        });
      };
      this.tables.table1.onRowClick = onRowClick.bind(this);
      this.tables.table2.onRowClick = onRowClick.bind(this);
      this.tables.table3.onRowClick = onRowClick.bind(this);
      this.tables.table4.onRowClick = onRowClick.bind(this);

      let payload = {
        territories: f.territory ? f.territory : [],
        countries: f.country ? f.country.map(i => i.label) : [],
        states: f.state ? f.state.map(i => i.label) : [],
        cities: f.city ? f.city.map(i => i.label) : [],
        period: f.period
      };

      if (fp.panelState.isCustomFiltersBar) {
        payload.expression = JSON.stringify(
          fp.prepareExpressions(f["custom-filter"])
        );
      }
      this.drawTable1(payload);
      this.drawTable2(payload);
      this.drawTable3(payload);
      this.drawTable4(payload);
    },
    async drawTable1(filter) {
      this.tables.table1.loading = true;

      let response = await this.$api.data["SalesTeam.IncreasingSalesAccounts"](
        filter
      );
      this.tables.table1.loading = false;
      this.tables.table1.dataSet = response;
    },
    async drawTable2(filter) {
      this.tables.table2.loading = true;
      let response = await this.$api.data["SalesTeam.BiggestNewAccounts"](
        filter
      );

      this.tables.table2.loading = false;
      this.tables.table2.dataSet = response;
    },
    async drawTable3(filter) {
      this.tables.table3.loading = true;
      let response = await this.$api.data["SalesTeam.OverdueRegularAccounts"](
        filter
      );
      this.tables.table3.loading = false;
      this.tables.table3.dataSet = response;
    },
    async drawTable4(filter) {
      this.tables.table4.loading = true;
      let response = await this.$api.data["SalesTeam.DecreasingSalesAccounts"](
        filter
      );
      this.tables.table4.loading = false;
      this.tables.table4.dataSet = response;
    }
    /*
    prepareDrillDownFilters(accountName) {
      let payload = {
        datasetName: "distribution-accounts-overview",
        //panelFilters: JSON.parse(JSON.stringify(this.$refs.filteringPanel.filters)),
        //selected: this.$refs.filteringPanel.selected
        panelFilters: [],
        selected: {}
      };
      if (
        this.$refs.filteringPanel &&
        this.$refs.filteringPanel.filters.find(f => f.name === "period")
      ) {
        payload.panelFilters.push(
          JSON.parse(
            JSON.stringify(
              this.$refs.filteringPanel.filters.find(f => f.name === "period")
            )
          )
        );
        payload.selected.period = this.$refs.filteringPanel.selected.period;
      }

      payload.panelFilters.push({
        type: "select",
        dataType: "string",
        title: "Account",
        tooltip: "Full account name",
        name: "account",
        trackby: "id",
        label: "label",
        multiple: false,
        options: [],
        selected: {},
        async: true,
        loading: false,
        startsWith: false
      });

      payload.selected.account = {
        id: undefined,
        label: accountName
      };

      this.saveSelectedFilters(payload);
    }*/
  },
  watch: {
    "filteringPanel.selected.country": function(newVal) {
      if (!newVal) return;

      this.filteringPanel.filters.find(
        f => f.name === "state"
      ).options = this.$helpers.getDistinctArray(
        this.filteringPanel.districts.filter(s =>
          newVal.map(l => l.label).includes(s.country)
        ),
        "state",
        "state",
        "id",
        "label"
      );
    },
    "filteringPanel.selected.state": function(newVal) {
      if (!newVal) return;

      this.filteringPanel.filters.find(
        f => f.name === "city"
      ).options = this.$helpers.getDistinctArray(
        this.filteringPanel.districts.filter(s =>
          newVal.map(l => l.label).includes(s.state)
        ),
        "city",
        "city",
        "id",
        "label"
      );
    }
  }
};
</script>

<style></style>
