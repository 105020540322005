var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('h2', [_vm._v("Sales Overview - Distribution")]), _c('br'), _c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": "distribution-sales-overview",
      "show-labels": false,
      "compact": false,
      "loaded": _vm.filteringPanel.loaded,
      "load-dictionaries": _vm.loadDictionaries,
      "districts": _vm.filteringPanel.districts,
      "filters": _vm.filteringPanel.filters
    },
    on: {
      "dict-loaded": _vm.onFilteringPanelLoad,
      "search": _vm.getData,
      "change": _vm.filterData,
      "custom-filter-selected": _vm.onCustomFilterSelected,
      "state-changed": _vm.onFilteringPanelStateChange
    }
  })], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', [_c('table-custom', {
    ref: "table1",
    attrs: {
      "name": "custom_table_sales_overview_1",
      "data": _vm.tables.table1.dataSet,
      "options": _vm.tables.table1.options
    },
    on: {
      "row-click": _vm.tables.table1.onRowClick
    }
  })], 1), _c('b-col', [_c('table-custom', {
    ref: "table2",
    attrs: {
      "name": "custom_table_sales_overview_2",
      "data": _vm.tables.table2.dataSet,
      "options": _vm.tables.table2.options
    },
    on: {
      "row-click": _vm.tables.table2.onRowClick
    }
  })], 1)], 1), _c('hr'), _c('b-row', [_c('b-col', [_c('table-custom', {
    ref: "table3",
    attrs: {
      "name": "custom_table_sales_overview_3",
      "data": _vm.tables.table3.dataSet,
      "options": _vm.tables.table3.options
    },
    on: {
      "row-click": _vm.tables.table3.onRowClick
    }
  })], 1), _c('b-col', [_c('table-custom', {
    ref: "table4",
    attrs: {
      "name": "custom_table_sales_overview_4",
      "data": _vm.tables.table4.dataSet,
      "options": _vm.tables.table4.options
    },
    on: {
      "row-click": _vm.tables.table4.onRowClick
    }
  })], 1)], 1), _c('hr')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }